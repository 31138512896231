import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import service from "../common/service";
import moment from "moment";
import Cookies from "universal-cookie";
const cookies = new Cookies();
let User = cookies.get("PAFUser");

// Components
// import Newsletter from "../components/Newsletter";


class Footer extends Component {

constructor(props) {
    super(props);
    this.apiServe = new service();
}

//   gotoPage(e,page){
//      this.props.history.push(page); 
//    // window.open(process.env.REACT_APP_HOME_OLD_URL + page)
//   }

gotoPage(to) {
    if (to === "schedule") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `schedule/`, "_self" );
    } else if (to === "new-frontier") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `new-frontier/`, "_self" );
    } else if (to === "beyond-films") {
      window.open( process.env.REACT_APP_HEADER_PROGRAM + `beyond-films/`, "_self" );
    } else if (to === "live-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `live-events`, "_self");
    } else if (to === "partner-events") {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `partner-events/`, "_self");
    } else if(to === 'highlights'){
      window.open(process.env.REACT_APP_HOME_URL + 'highlight' ,'_self')
    } else if(to === 'about'){
      window.open(process.env.REACT_APP_HOME_URL + 'about' ,'_self')
    } else if(to === 'community-agreement'){
      window.open(process.env.REACT_APP_HOME_URL + 'community-agreement' ,'_self')
    } else {
      window.open(process.env.REACT_APP_HEADER_PROGRAM + `films/`, "_self");
    }
  }
  gotoNewTab(e, link) {
    e.preventDefault();
    window.open(link);
}
  goto(Link) {
    window.open(Link, "_self")
  }
    logout = () => {
    if (User && User.token) {
        this.props.dispatch({ type: 'USER', payload: {} })
        // eslint-disable-next-line
        document.cookie = "PAFUser" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        document.cookie = "PAFProjectId" + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

        cookies.remove("PAFUser", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });
        cookies.remove("PAFProjectId", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: "/" });

        let Zone = localStorage.getItem('TIMEZONE')
        localStorage.clear();
        if (Zone) localStorage.setItem('TIMEZONE', Zone)
        // window.open(process.env.REACT_APP_HOME_URL + 'sign-in', "_self")
    }
};
  render() {
    let particularUser = cookies.get("privateScreeningUser") ||cookies.get("SundanceNewUser") || cookies.get("particularNewUser") || cookies.get("STYXKEY_sundanceUser");
    let userInfo = cookies.get("STYXKEY_sundanceUser")
    return (
      <footer>
        {/* Newsletter */}
        {/* <Newsletter /> */}

        {/* Footer */}
        <div className="sd_large_footer">
            <div className="sd_footer_links_wpr">
                <div className="sd_large_container sd_footer_container sd_container_1440">
                <div className="sd_footer_links_content sd_flex sd_desktop_footer">
                    <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Top Links
                        </label>
                        <ul className={`sd_links_list`} id="helpFullLink">
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}>Merch </span>
                          </li>
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_HIGHLIGHTS_UTC).startDate, JSON.parse(process.env.REACT_APP_HIGHLIGHTS_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HOME_URL + 'highlights/')}> Highlights </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_MAP_UTC).startDate, JSON.parse(process.env.REACT_APP_MAP_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_MAP)}> Map </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_VENUES_UTC).startDate, JSON.parse(process.env.REACT_APP_VENUES_UTC).endDate) && <li><span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_VENUES_EXPERIENCE)}>Venues</span></li>}
                          {moment().isAfter(process.env.REACT_APP_LODGING_DATE) && <li><span onClick={this.goto.bind(this,process.env.REACT_APP_LODGING_URL + 'lodging/')}>Lodging</span></li>}
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_INDUSTRY_URL)}> Sundance Industry Office </span>
                          </li>
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_PRESS_URL)}> Press </span>
                          </li>
                          <li>
                            <span onClick={this.goto.bind(this, 'https://www.sundance.org/customersupport')}> Customer Support </span>
                          </li>
                        </ul>
                        </div>
                    </div>
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_PROGRAM_UTC).startDate, JSON.parse(process.env.REACT_APP_PROGRAM_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <div className="sd_flex sd_aligncenter">
                        <label className={`sd_flex sd_aligncenter`}>
                          Program
                        </label>
                        </div>
                        <ul className={`sd_links_list`} id='programLink'>
                            <li>
                            <span onClick={this.gotoPage.bind(this, 'films')}>Films</span>
                            </li>
                            {moment().isBetween(JSON.parse(process.env.REACT_APP_BEYOND_UTC).startDate, JSON.parse(process.env.REACT_APP_BEYOND_UTC).endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'beyond-films')} >Beyond Film</span>
                            </li>}
                            {moment().isBefore(process.env.REACT_APP_FPG3_ENABLE_DATE) && <li>
                              <span onClick={this.gotoPage.bind(this, 'partner-events')} >Partner Events</span>
                            </li>}
                            {moment().isBetween(JSON.parse(process.env.REACT_APP_SCHEDULE_UTC).startDate, JSON.parse(process.env.REACT_APP_SCHEDULE_UTC).endDate) && <li><span onClick={this.gotoPage.bind(this, 'schedule')}>Schedule</span></li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Tickets</label>
                        
                        <ul className={`sd_links_list`} id="ticketLink">
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'in-person')}> In Person </span>
                          </li>
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_ONLINE_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_ONLINE_UTC).endDate) && <li>
                          <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'online')}>Online</span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_COMPARE_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_COMPARE_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'compare-ticket')}> Compare Tickets </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_FAQ_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_FAQ_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'faq')}>FAQ</span>
                          </li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_HOW_TO_FEST_UTC).startDate, JSON.parse(process.env.REACT_APP_HOW_TO_FEST_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        How to Fest</label>
                        
                        <ul className={`sd_links_list`} id="howToFestLink">
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/at-a-glance')}>  At a Glance </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/ticketing')}> Ticketing </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/attend-in-person')}>Attend in Person</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/watch-online')}>Watch Online</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/accessibility')}> Accessibility </span>
                          </li>
                        </ul>
    
                        </div>
                    </div>}
                </div>
                <div className="sd_footer_links_content sd_flex sd_mobile_footer">
                  <div>
                    <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Top Links
                        </label>
                        <ul className={`sd_links_list`} id="helpFullLink">
                          <li>
                            <span onClick={(e) => this.gotoNewTab(e, process.env.REACT_APP_HEADER_FESTIVAL_MERCH)}>Merch </span>
                          </li>
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_HIGHLIGHTS_UTC).startDate, JSON.parse(process.env.REACT_APP_HIGHLIGHTS_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HOME_URL + 'highlights/')}> Highlights </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_MAP_UTC).startDate, JSON.parse(process.env.REACT_APP_MAP_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_MAP)}> Map </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_VENUES_UTC).startDate, JSON.parse(process.env.REACT_APP_VENUES_UTC).endDate) && <li><span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_VENUES_EXPERIENCE)}>Venues</span></li>}
                          {moment().isAfter(process.env.REACT_APP_LODGING_DATE) && <li><span onClick={this.goto.bind(this,process.env.REACT_APP_LODGING_URL + 'lodging/')}>Lodging</span></li>}
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_INDUSTRY_URL)}> Sundance Industry Office </span>
                          </li>
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_PRESS_URL)}> Press </span>
                          </li>
                          <li>
                            <span onClick={this.goto.bind(this, 'https://www.sundance.org/customersupport')}> Customer Support </span>
                          </li>
                        </ul>
                        </div>
                    </div>
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_HOW_TO_FEST_UTC).startDate, JSON.parse(process.env.REACT_APP_HOW_TO_FEST_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        How to Fest</label>
                        
                        <ul className={`sd_links_list`} id="howToFestLink">
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/at-a-glance')}>  At a Glance </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/ticketing')}> Ticketing </span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/attend-in-person')}>Attend in Person</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/watch-online')}>Watch Online</span>
                          </li>
                          <li>
                              <span onClick={this.goto.bind(this, process.env.REACT_APP_HEADER_HTF+'/accessibility')}> Accessibility </span>
                          </li>
                        </ul>
    
                        </div>
                    </div>}
                  </div>
                  <div>
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_PROGRAM_UTC).startDate, JSON.parse(process.env.REACT_APP_PROGRAM_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <div className="sd_flex sd_aligncenter">
                        <label className={`sd_flex sd_aligncenter`}>
                          Program
                        </label>
                        </div>
                        <ul className={`sd_links_list`} id='programLink'>
                            <li>
                            <span onClick={this.gotoPage.bind(this, 'films')}>Films</span>
                            </li>
                            {moment().isBetween(JSON.parse(process.env.REACT_APP_BEYOND_UTC).startDate, JSON.parse(process.env.REACT_APP_BEYOND_UTC).endDate) && <li>
                            <span onClick={this.gotoPage.bind(this, 'beyond-films')} >Beyond Film</span>
                            </li>}
                            {moment().isBefore(process.env.REACT_APP_FPG3_ENABLE_DATE) && <li>
                              <span onClick={this.gotoPage.bind(this, 'partner-events')} >Partner Events</span>
                            </li>}
                            {moment().isBetween(JSON.parse(process.env.REACT_APP_SCHEDULE_UTC).startDate, JSON.parse(process.env.REACT_APP_SCHEDULE_UTC).endDate) && <li><span onClick={this.gotoPage.bind(this, 'schedule')}>Schedule</span></li>}
                        </ul>
                        </div>
                    </div>}
                    {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_UTC).endDate) && <div className="sd_footer_links">
                        <div>
                        <label className={`sd_flex sd_aligncenter`}>
                        Tickets</label>
                        
                        <ul className={`sd_links_list`} id="ticketLink">
                          <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'in-person')}> In Person </span>
                          </li>
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_ONLINE_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_ONLINE_UTC).endDate) && <li>
                          <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'online')}>Online</span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_COMPARE_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_COMPARE_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS+'compare-ticket')}> Compare Tickets </span>
                          </li>}
                          {moment().isBetween(JSON.parse(process.env.REACT_APP_TICKETS_FAQ_UTC).startDate, JSON.parse(process.env.REACT_APP_TICKETS_FAQ_UTC).endDate) && <li>
                            <span onClick={this.goto.bind(this,process.env.REACT_APP_HEADER_BUY_TICKETS + 'faq')}>FAQ</span>
                          </li>}
                        </ul>
                        </div>
                    </div>}
                  </div>
                </div>
                </div>
            </div>
            <div className="sd_footer_links_wpr">
            <div className="">
                <div className="sd_footer_wpr sd_flex sd_aligncenter sd_justbetween">
                    <div className="sd_flex sd_aligncenter sd_flex_1_auto sd_justbetween">
                        <div className="sd_footer_logo">
                            <div className="sd_footer_logos sd_aligncenter">
                            <div className="sd_footer_main_logo">
                            <Link onClick={this.goto.bind(this, process.env.REACT_APP_HOME_OLD_URL)} rel="noopener noreferrer" >
                                <img src={this.apiServe.imageUrl+'assest/'+process.env.REACT_APP_ENVIRONMENT+'/footer_logo.svg'} alt="Sundance Institute" title="Sundance Institute" />
                            </Link>
                            {/* <Link onClick={this.goto.bind(this, process.env.REACT_APP_HOME_OLD_URL)} rel="noopener noreferrer" className="footer_webby_logo">
                                <img src='/images/footer_webby_honoree.png' alt="Sundance Institute" title="Sundance Institute" />
                            </Link> */}
                            </div>
                        </div>
                            <p className="sd_textuppercase">
                            {" "}
                            Copyright &copy; {new Date().getFullYear()} Sundance Institute,
                            All Rights Reserved{" "}
                        </p>
                        </div>
                        <div className="sd_footer_navbar">  
                            <ul>
                                <li>
                                    {/* <a href="https://digital.sundance.org/#about" rel="noreferrer" target="_blank"> About Sundance Institute </a> */}
                                    <a href="https://www.sundance.org/about/us" target="_blank" > About Sundance Institute </a>
                                    {/* <Link onClick={(e)=>this.gotoPage(e,"about")} > About Sundance Institute </Link> */}
                                </li>
                                {/* <li>
                                <a href="https://digital.sundance.org/#help" rel="noreferrer" target="_blank"> Help </a>
                                    <Link onClick={(e)=>this.gotoPage(e,"help")}> Help </Link>
                                </li> */}
                                <li>
                                  <Link onClick={this.gotoPage.bind(this,"community-agreement")}>  Community Agreement</Link>
                                </li>
                            </ul>
                        </div>
                    </div>  
                    <div className="sd_footer_navigation_bar">
                    <div className="sd_footer_nav">
                    <ul className="sd_flex sd_aligncenter">
                        <li>
                        <a
                            href="https://collab.sundance.org/"
                            target="_blank"
                            rel="noreferrer" className="sd_collab_logo"
                        >
                            <img
                            src={this.apiServe.imageUrl+'assest/'+process.env.REACT_APP_ENVIRONMENT+'/collab_logo.svg'}
                            alt="Sundance Collab"
                            title="Sundance Collab"
                            />
                        </a>
                        </li>
                        <li>
                        <ul className="sd_flex sd_aligncenter">
                            <li>
                            <a
                                href="https://www.instagram.com/sundanceorg/"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <i class="fab fa-instagram"></i>
                            </a>
                            </li>
                            <li className="sff_tiktok_icon">
                            <a
                                href="https://www.tiktok.com/@sundanceorg/"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <i class="fab fa-tiktok"></i>
                            </a>
                            </li>
                             <li>
                            <a
                                href="https://www.youtube.com/c/sundancefilmfestival"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <i class="fab fa-youtube"></i>
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://www.facebook.com/sundance"
                                target="_blank"
                                rel="noreferrer"
                            >
                              <i class="fab fa-facebook-f"></i>
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://twitter.com/sundancefest"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                src="/images/sff_twitter_icon.svg"
                                alt="Twitter"
                                title="Twitter"
                                />
                            </a>
                            </li>
                            <li>
                            <a
                                href="https://threads.net/@sundanceorg"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                src="/images/sd_thread_icon.svg"
                                alt="thread"
                                title="thread"
                                />
                            </a>
                            </li>
                           
                        </ul>
                        </li>
                    </ul>
                    <ul className="sd_flex sd_aligncenter">
                        <li>
                        <a
                            href="https://www.sundance.org/about/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        </li>
                        <li>
                        <a
                            href="https://www.sundance.org/about/privacy-policy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Terms &amp; Conditions 
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                    <div className="sd_footer_copyright sd_hidden">
                    <p className="sd_textuppercase">
                        {" "}
                        Copyright &copy; {new Date().getFullYear()} Sundance Institute,
                        All Rights Reserved
                    </p>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </footer>
    );
  }
}

export default withRouter(Footer);