
import React, { useState, useEffect, useRef } from 'react';
// Layouts
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
// PAF Header
import Dropdown from '../../components/Dropdown';
// import PlusFieldIcon from '../../icons/PlusFieldIcon';
// import RemoveFieldIcon from '../../icons/RemoveFieldIcon';
import TextField from '../../components/TextField';
import DropdownQuestion from '../../components/DropdownQuestion';
import TextArea from '../../components/TextArea';
import Checkbox from '../../components/Checkbox';
// import {  adobelist } from '../../common/constant'
// import { Link } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import {  useSelector } from 'react-redux';
import PreviewFormFooter from './PreviewFormFooter';
import PreviewFormHeader from './PreviewFormHeader';
import service from '../../common/service';
import { useParams } from 'react-router-dom';

function Step9() {
    // let sub
    // const dispatch = useDispatch();
    // const [, updateState] = React.useState();
    // const forceUpdate = React.useCallback(() => updateState({}), []);
    // const [allSelected, setAllSelected] = useState(false)
    // const [allSelected1, setAllSelected1] = useState(false)
    // const apiServe = new service();
    const apiServe = new service();
    const { ProjectId } = useParams();
    const refcode = useSelector(e => e.refcode);
    const ProjectInfo = useSelector(e => e.SharedProjectInfo);
    const pafType = useSelector(e => e.SharedPafType);
    const Validator = useRef(new SimpleReactValidator({
        autoForceUpdate: this,
        validators: {
            Require: {  // name the rule
                message: 'This field is required.',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            onecamerabrandandmodel: {  // name the rule
                message: 'Information for at least one camera is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            onelensbrandandmodel: {  // name the rule
                message: 'Information for at least one lens is required',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            Pleaseentermodel: {  // name the rule
                message: 'Additional information is required when other is selected',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            descILC: {  // name the rule
                message: 'Additional information is required when ILC is selected',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },
            descSpecialtyBrand: {  // name the rule
                message: 'Additional information is required when specialty brand is selected',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[\s\t\r\n]*\S+/ig) && params.indexOf(val) === -1
                },
                required: true  // optional
            },

        }
    }))
    const [adobeCheck, setAdobeCheck] = useState([
        { name: "none", value: "None", isChecked: ProjectInfo.adobeProductUse?.none === undefined ? false : ProjectInfo.adobeProductUse?.none },
        { name: "premierePro", value: "Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.premierePro === undefined ? false : ProjectInfo.adobeProductUse?.premierePro },
        { name: "productionsInPremierePro", value: "Productions in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.productionsInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.productionsInPremierePro },
        { name: "teamProjectsInPremierePro", value: "Team Projects in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.teamProjectsInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.teamProjectsInPremierePro },
        { name: "textBasedEditingInPremierePro", value: "Text-Based Editing Features in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.textBasedEditingInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.textBasedEditingInPremierePro },
        { name: "afterEffects", value: "After Effects", isChecked: ProjectInfo.adobeProductUse?.afterEffects === undefined ? false : ProjectInfo.adobeProductUse?.afterEffects },
        { name: "frameIo", value: "Frame.io", isChecked: ProjectInfo.adobeProductUse?.frameIo === undefined ? false : ProjectInfo.adobeProductUse?.frameIo },
        { name: "cameraToCloud", value: "Camera to Cloud", isChecked: ProjectInfo.adobeProductUse?.cameraToCloud === undefined ? false : ProjectInfo.adobeProductUse?.cameraToCloud },
        { name: "audition", value: "Audition", isChecked: ProjectInfo.adobeProductUse?.audition === undefined ? false : ProjectInfo.adobeProductUse?.audition },
        { name: "photoshop", value: "Photoshop", isChecked: ProjectInfo.adobeProductUse?.photoshop === undefined ? false : ProjectInfo.adobeProductUse?.photoshop },
        { name: "illustrator", value: "Illustrator", isChecked: ProjectInfo.adobeProductUse?.illustrator === undefined ? false : ProjectInfo.adobeProductUse?.illustrator },
        { name: "adobeMediaEncoder", value: "Adobe Media Encoder", isChecked: ProjectInfo.adobeProductUse?.adobeMediaEncoder === undefined ? false : ProjectInfo.adobeProductUse?.adobeMediaEncoder },
        { name: "substance3D", value: "Substance 3D", isChecked: ProjectInfo.adobeProductUse?.substance3D === undefined ? false : ProjectInfo.adobeProductUse?.substance3D },
        { name: "other", value: "Other", isChecked: ProjectInfo.adobeProductUse?.other === undefined ? false : ProjectInfo.adobeProductUse?.other }
    ])


    const [stockFootage, setStockFootage] = useState([
        { name: "adobeStock", value: "Adobe Stock", isChecked: ProjectInfo.stockFootageOther?.adobeStock === undefined ? false : ProjectInfo.stockFootageOther?.adobeStock },
        { name: "shitterstock", value: "Shutterstock", isChecked: ProjectInfo.stockFootageOther?.shitterstock === undefined ? false : ProjectInfo.stockFootageOther?.shitterstock },
        { name: "pond5", value: "Pond5", isChecked: ProjectInfo.stockFootageOther?.pond5 === undefined ? false : ProjectInfo.stockFootageOther?.pond5 },
        { name: "getty", value: "Getty", isChecked: ProjectInfo.stockFootageOther?.getty === undefined ? false : ProjectInfo.stockFootageOther?.getty },
        { name: "dissolve", value: "Dissolve", isChecked: ProjectInfo.stockFootageOther?.dissolve === undefined ? false : ProjectInfo.stockFootageOther?.dissolve },
        // { name: "noUseAnyStock", value: "I did not use any stock footage", isChecked: ProjectInfo.stockFootageOther?.noUseAnyStock === undefined ? false : ProjectInfo.stockFootageOther?.noUseAnyStock },
        { name: "other", value: "other", isChecked: ProjectInfo.stockFootageOther?.other === undefined ? false : ProjectInfo.stockFootageOther?.other },
    ])

    const [technologyHardware, setTechnologyHardware] = useState([])
    const [realTimes3DTools, setRealTimes3DTools] = useState([
        { name: "notUseRealTimes3D", value: "I did not use a 3D real-time engine", isChecked: ProjectInfo.realTimes3DToolsOther?.notUseRealTimes3D === undefined ? false : ProjectInfo.realTimes3DToolsOther?.notUseRealTimes3D },
        { name: "Unity", value: "Unity", isChecked: ProjectInfo.realTimes3DToolsOther?.Unity === undefined ? false : ProjectInfo.realTimes3DToolsOther?.Unity },
        { name: "Unreal", value: "Unreal", isChecked: ProjectInfo.realTimes3DToolsOther?.Unreal === undefined ? false : ProjectInfo.realTimes3DToolsOther?.Unreal },
        { name: "AdobeAero", value: "Adobe Aero", isChecked: ProjectInfo.realTimes3DToolsOther?.AdobeAero === undefined ? false : ProjectInfo.realTimes3DToolsOther?.AdobeAero },
        { name: "InHouse", value: "In-House/Proprietary", isChecked: ProjectInfo.realTimes3DToolsOther?.InHouse === undefined ? false : ProjectInfo.realTimes3DToolsOther?.InHouse },
        // { name: "noUseAnyStock", value: "I did not use any stock footage", isChecked: ProjectInfo.realTimes3DToolsOther?.noUseAnyStock === undefined ? false : ProjectInfo.realTimes3DToolsOther?.noUseAnyStock },
        { name: "other", value: "Other", isChecked: ProjectInfo.realTimes3DToolsOther?.other === undefined ? false : ProjectInfo.realTimes3DToolsOther?.other },
    ])

    const [option] = useState({
        Flag1: ['Purchase', 'Rent', 'N/A'],
        Flag2: ['Anamorphic', 'Spherical', 'Both', 'N/A'],
        Flag3: ['Format', 'Features', 'Brand Reputation', 'Recommendations from Colleagues or Friends', 'Price', 'Familiarity with Brand', 'Availability at Rental House', 'Rental House Staff', 'Size/Weight', 'Other'],
        SoftwareData: ['Avid Media Composer', 'Adobe Premiere Pro', 'Final Cut Pro X', 'Final Cut Pro - earlier version', 'DaVinci Resolve', 'Other'],
    //   Flag5: ['Yes', "No, we didn't use any Adobe products", "No, we didn't use Adobe Creative Cloud, but we did you an older version (Adobe Creative Suite, Production Premium)"],
        Flag5: ['Yes', "No, we didn't use any Adobe products"],
        Flag6: ['PC- Dell', 'PC- HP', 'Mac- Apple', 'Other'],
        Flag7: ['Storage Devices/Hard Drives', ' Laptop/Desktop Computer', 'Audio Software/Hardware(pre/post)', 'Sound Mixing Software', 'Graphics Software', 'Mobile Phone Technology', 'Game Engine', 'VR/AR Headset', 'None'],
        Flag8: ['I did not use a 3D real-time engine', 'Unity', 'Unreal', 'Adobe Aero', ' In-House/proprietary', 'Other'],
        Flag9: ['Yes', 'No'],
        Flag10: ['Kickstarter', 'Indie-gogo', 'Slated', 'Rally', 'Crowdfunder', 'RocketHub', 'Other', 'None'],
    })


    const [camera] = useState({
        ARRI: ['Alexa', 'Alexa LF', 'Alexa Mini', 'Alexa 65', 'Amira', 'Arriflex Film Camera', 'Other'],
        AATON: ['A-Minima', 'Delta', 'Penelope', 'Xtera', 'Other'],
        BLACKMAGIC: ["Cinema Camera 6K", "Cinema Camera 6K G2", "URSA Cine 12K", "URSA Cine 17K", "PYXIS 6K", "URSA Mini Pro 12K", "URSA Mini Pro 4.6K", "Pocket Cinema Camera", "Micro Cinema Camera", "URSA Mini", "Other"],
        CANON: [ "C700 FF", "C700", "C500 Mark II", "C500", "C400", "C300 Mark III", "C300 Mark II", "C300", "C200", "C100", "C100 Mark II", "C70", "EOS R5 C", "EOS R5 Mark II", "ME20F-SH or ME200S-SH", "XA and XF Pro Camcorder Series", "ILC", "Other" ],
        GOPRO: ['Hero', 'Other'],
        Kinefinity:[ "MAVO Edge 8K", "MAVO Edge 6K", "MAVO S35 MKII", "MAVO LF MKII" ],
        // NoCamera: ['Animation', 'Archival Footage'],
        // SpeedGrade: ['Alexa', 'Alexa 65', 'Alexa LF', 'Alexa Mini', 'Amira', 'Arriflex Film Camera'],
        PANASONIC: [ "EVA1", "GH5, GH5 II or GH5s", "GH6", "GH7", "S1", "S1H", "S5 II series", "S9", "VariCam Pure", "VariCam HS", "VariCam 35", "VariCam LT", "Other" ],
        PANAVISION: ['Genesis', 'Millenium', 'Platinum', 'DXL', 'Other'],
        PHANTOM: ['Gold', 'Flex', 'Miro ', 'Other'],
        RED: [ "Raptor", "Komodo", "Komodo X", "Epic", "Dragon", "Monstro", "Raven", "Scarlet", "Weapon", "Other"],
        SONY: [ "LR1", "A7S Series", "A7R Series", "A7 Series", "A9 Series", "Alpha 1", "ZV Series", "FX30", "FX3", "XF6", "FX9", "F5", "F55", "F65", "FS5 Series", "FS7 Series", "FS700", "Venice", "Venice 2", "BURANO", "FR7", "Other" ],
        // SpecialtyBrand: ['Other'],
        OTHER: ['Did not use a camera - Animation or Archival Footage','Specialty Brand']
    });

    const [lens] = useState({
        '7artisans': ['Other'],
        ANGENIEUX: ['Optimo Anamorphic', 'Optimo', 'Type EZ', 'Other'],
        ARRIZeiss: ['Master Anamorphic', 'Master Primes', 'Ultra Primes', 'Compact Primes', 'Other'],
        ATLASLENSCO: ['Orion Series', 'Mercury Series'],
        BLAZAR: ['Other'],
        CANON: [ 'RF "Still" Series', 'EF "Still" Series', 'CN-R Cinema Series', 'CN-E Cinema Series', 'CINE-SERVO Series', 'COMPACT-SERVO Series', 'Flex Zoom Series', 'Sumire Prime Series', 'FD lenses', 'K35', 'Other' ],
        COOKE: [ "5i", "Anamorphic", "Panchro Classic", "Mini S4i", "S4i", "S7i", "SP3", "Varotal", "Other" ],
        DZOFilm:['Other'],
        FUJINON: ['MK series', 'Premista Series', 'Cabrio Series','Premier series', 'Other'],
        HAWK: ['V-Lite Series', 'V-Series', 'Other'],
        IRIX: ['Other'],
        KOWA: ['Anamorphics', 'Spherical Primes', 'Other'],
        LEICA: ['Other'],
        LEITZ: ['Other'],
        MEIKE: ['Other'],
        MITAKONZhongyi: ['Other'],
        NiSi: ['Other'],
        PANASONIC: ['Other'],
        PANAVISION: ['Anamorphic', 'Primo', 'Super Panavision 70 ', 'Other'],
        ROKINON: ['XEEN series', 'Other'],
        SAMYANG: ['Other'],
        SCHNEIDER: ['Other'],
        SIGMA: ['Classic Art', 'High speed', 'High speed Art', 'Other'],
        Sirui: ['Other'],
        SLRMAGIC: ['Other'],
        SONY: ['CineAlta', 'Other'],
        TAMRON: ['Other'],
        TOKINA: ['Cinema', 'Cinema ATX', 'Cinema Vista series', 'Other'],
        VENUSOPTICS:['Other'],
        VILTROX:['Other'],
        ZEISS: ['Master Anamorphic', 'Master Primes', 'Supreme series', 'Photo Lenses', 'Ultra Primes', 'Other'],
        OTHER : ['Other']
    });

    const [extrafield,] = useState(ProjectInfo.softwareUsedToEdit === 'Other' ? true : false);
    const [adobeOtherFlag,] = useState(ProjectInfo.adobeProductUse?.other === undefined ? false : ProjectInfo.adobeProductUse?.other );
    const [stockOtherFlag,] = useState(ProjectInfo.stockFootage?.other === undefined ? false : ProjectInfo.stockFootage?.other);
    const [realTimeOtherFlag, setRealTimeOtherFlag] = useState(ProjectInfo.realTimes3DTools?.other === undefined ? false : ProjectInfo.realTimes3DTools?.other);
    const [alldata, setalldata] = useState({
        cameraAnamorphicOrSpecialLense: ProjectInfo.cameraAnamorphicOrSpecialLense,
        cameraPurchaseOrRent: ProjectInfo.cameraPurchaseOrRent,
        influencedCamera: ProjectInfo.influencedCamera,
        softwareUsedToEdit: ProjectInfo.softwareUsedToEdit ? ProjectInfo.softwareUsedToEdit : 'Other',
        useAdobeProducts: ProjectInfo.useAdobeProducts,
        useHardware: ProjectInfo.useHardware,
        useOtherTechnologyHardware: ProjectInfo.useOtherTechnologyHardware,
        useStockFootage: ProjectInfo.useStockFootage,
        fundingPlatform: ProjectInfo.fundingPlatform,
        sharingDistribution: ProjectInfo.sharingDistribution,
        hybridDistribution: ProjectInfo.hybridDistribution,
        useDropbox: ProjectInfo.useDropbox,
        // realTimes3DTools: ProjectInfo.realTimes3DTools

    })
    console.log(`abcd`, alldata)

    const [adobeListOther, setadobeListOther] = useState({
        otherDesc: ProjectInfo.adobeProductUse?.otherDesc ? ProjectInfo.adobeProductUse?.otherDesc : ''
    })

    const [stockFootageOther, setstockFootageOther] = useState({
        otherDesc: ProjectInfo.stockFootage?.otherDesc ? ProjectInfo.stockFootage?.otherDesc : ''
    })
    const [realTimes3DToolsOther, setRealTimes3DToolsOther] = useState({
        otherDesc: ProjectInfo.realTimes3DTools?.otherDesc ? ProjectInfo.realTimes3DTools?.otherDesc : ''
    })

    const [formnine, setFormnine] = useState({
        cameras: ProjectInfo.cameras?.length > 0 ? ProjectInfo.cameras : [{ brand: '', model: '', desc: '', _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString() }],
        useCameraLence: ProjectInfo.useCameraLence?.length > 0 ? ProjectInfo.useCameraLence : [{ brand: '', model: '', desc: '', _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString() }]
    })

    const onLoad = (e) => {
        apiServe.sendevent('paf|project|previewForm', { projectUserId: ProjectId })
        apiServe.sendevent('paf|project|CheckValidEmail', { projectUserId: ProjectId ,referralCode:refcode})
    }

    useEffect(() => {
        window.addEventListener('load',onLoad)
        return () => {
            window.removeEventListener('load',onLoad)
        }
    },[])

    useEffect(() => {
        setalldata({
            cameraAnamorphicOrSpecialLense: ProjectInfo.cameraAnamorphicOrSpecialLense,
            cameraPurchaseOrRent: ProjectInfo.cameraPurchaseOrRent,
            influencedCamera: ProjectInfo.influencedCamera,
            softwareUsedToEdit: ProjectInfo.softwareUsedToEdit ? ProjectInfo.softwareUsedToEdit : 'Other',
            useAdobeProducts: ProjectInfo.useAdobeProducts,
            useHardware: ProjectInfo.useHardware,
            useOtherTechnologyHardware: ProjectInfo.useOtherTechnologyHardware,
            useStockFootage: ProjectInfo.useStockFootage,
            fundingPlatform: ProjectInfo.fundingPlatform,
            sharingDistribution: ProjectInfo.sharingDistribution,
            hybridDistribution: ProjectInfo.hybridDistribution,
            useDropbox: ProjectInfo.useDropbox,
        })
        setadobeListOther({
            otherDesc: ProjectInfo.adobeProductUse?.otherDesc ? ProjectInfo.adobeProductUse?.otherDesc : ''
        })
        setstockFootageOther({
            otherDesc: ProjectInfo.stockFootage?.otherDesc ? ProjectInfo.stockFootage?.otherDesc : ''
        })
        setRealTimes3DToolsOther({
            otherDesc: ProjectInfo.realTimes3DTools?.otherDesc ? ProjectInfo.realTimes3DTools?.otherDesc : ''
        })
        setTechnologyHardware([
            { name: "storageDevices", value: "Storage Devices/Hard Drives", isChecked: ProjectInfo.useOtherTechnologyHardware?.storageDevices === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.storageDevices },
            { name: "desktopLaptop", value: "Laptop/Desktop Computer", isChecked: ProjectInfo.useOtherTechnologyHardware?.desktopLaptop === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.desktopLaptop },
            { name: "audioSoftware", value: "Audio Software/Hardware(pre/post)", isChecked: ProjectInfo.useOtherTechnologyHardware?.audioSoftware === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.audioSoftware },
            { name: "soundMixingSoftware", value: "Sound Mixing Software", isChecked: ProjectInfo.useOtherTechnologyHardware?.soundMixingSoftware === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.soundMixingSoftware },
            { name: "graphicsSoftware", value: "Graphics Software", isChecked: ProjectInfo.useOtherTechnologyHardware?.graphicsSoftware === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.graphicsSoftware },
            { name: "mobilePhoneTechnology", value: "Mobile Phone Technology", isChecked: ProjectInfo.useOtherTechnologyHardware?.mobilePhoneTechnology === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.mobilePhoneTechnology },
            { name: "gameEngine", value: "Game Engine", isChecked: ProjectInfo.useOtherTechnologyHardware?.gameEngine === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.gameEngine },
            { name: "vrArHeadset", value: "VR/AR Headset", isChecked: ProjectInfo.useOtherTechnologyHardware?.vrArHeadset === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.vrArHeadset },
            { name: "None", value: "None", isChecked: ProjectInfo.useOtherTechnologyHardware?.None === undefined ? false : ProjectInfo.useOtherTechnologyHardware?.None },
        ])
        setStockFootage([
            { name: "adobeStock", value: "Adobe Stock", isChecked: ProjectInfo.stockFootage?.adobeStock === undefined ? false : ProjectInfo.stockFootage?.adobeStock },
            { name: "shitterstock", value: "Shutterstock", isChecked: ProjectInfo.stockFootage?.shitterstock === undefined ? false : ProjectInfo.stockFootage?.shitterstock },
            { name: "pond5", value: "Pond5", isChecked: ProjectInfo.stockFootage?.pond5 === undefined ? false : ProjectInfo.stockFootage?.pond5 },
            { name: "getty", value: "Getty", isChecked: ProjectInfo.stockFootage?.getty === undefined ? false : ProjectInfo.stockFootage?.getty },
            { name: "dissolve", value: "Dissolve", isChecked: ProjectInfo.stockFootage?.dissolve === undefined ? false : ProjectInfo.stockFootage?.dissolve },
            // { name: "noUseAnyStock", value: "I did not use any stock footage", isChecked: ProjectInfo.stockFootage?.noUseAnyStock === undefined ? false : ProjectInfo.stockFootage?.noUseAnyStock },
            { name: "other", value: "Other", isChecked: ProjectInfo.stockFootage?.other === undefined ? false : ProjectInfo.stockFootage?.other },
        ])
        setRealTimes3DTools([
            { name: "notUseRealTimes3D", value: "I did not use a 3D real-time engine", isChecked: ProjectInfo.realTimes3DTools?.notUseRealTimes3D === undefined ? false : ProjectInfo.realTimes3DTools?.notUseRealTimes3D },
            { name: "Unity", value: "Unity", isChecked: ProjectInfo.realTimes3DTools?.Unity === undefined ? false : ProjectInfo.realTimes3DTools?.Unity },
            { name: "Unreal", value: "Unreal", isChecked: ProjectInfo.realTimes3DTools?.Unreal === undefined ? false : ProjectInfo.realTimes3DTools?.Unreal },
            { name: "AdobeAero", value: "Adobe Aero", isChecked: ProjectInfo.realTimes3DTools?.AdobeAero === undefined ? false : ProjectInfo.realTimes3DTools?.AdobeAero },
            { name: "InHouse", value: "In-House/Proprietary", isChecked: ProjectInfo.realTimes3DTools?.InHouse === undefined ? false : ProjectInfo.realTimes3DTools?.InHouse },
            // { name: "noUseAnyStock", value: "I did not use any stock footage", isChecked: ProjectInfo.realTimes3DTools?.noUseAnyStock === undefined ? false : ProjectInfo.realTimes3DTools?.noUseAnyStock },
            { name: "other", value: "Other", isChecked: ProjectInfo.realTimes3DTools?.other === undefined ? false : ProjectInfo.realTimes3DTools?.other }
        ])
        setAdobeCheck([
            { name: "none", value: "None", isChecked: ProjectInfo.adobeProductUse?.none === undefined ? false : ProjectInfo.adobeProductUse?.none },
            { name: "premierePro", value: "Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.premierePro === undefined ? false : ProjectInfo.adobeProductUse?.premierePro },
            { name: "productionsInPremierePro", value: "Productions in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.productionsInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.productionsInPremierePro },
            { name: "teamProjectsInPremierePro", value: "Team Projects in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.teamProjectsInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.teamProjectsInPremierePro },
            { name: "textBasedEditingInPremierePro", value: "Text-Based Editing Features in Premiere Pro", isChecked: ProjectInfo.adobeProductUse?.textBasedEditingInPremierePro === undefined ? false : ProjectInfo.adobeProductUse?.textbasededitinginpremierepro },
            { name: "afterEffects", value: "After Effects", isChecked: ProjectInfo.adobeProductUse?.afterEffects === undefined ? false : ProjectInfo.adobeProductUse?.afterEffects },
            { name: "frameIo", value: "Frame.io", isChecked: ProjectInfo.adobeProductUse?.frameIo === undefined ? false : ProjectInfo.adobeProductUse?.frameIo },
            { name: "cameraToCloud", value: "Camera to Cloud", isChecked: ProjectInfo.adobeProductUse?.cameraToCloud === undefined ? false : ProjectInfo.adobeProductUse?.cameraToCloud },
            { name: "audition", value: "Audition", isChecked: ProjectInfo.adobeProductUse?.audition === undefined ? false : ProjectInfo.adobeProductUse?.audition },
            { name: "photoshop", value: "Photoshop", isChecked: ProjectInfo.adobeProductUse?.photoshop === undefined ? false : ProjectInfo.adobeProductUse?.photoshop },
            { name: "illustrator", value: "Illustrator", isChecked: ProjectInfo.adobeProductUse?.illustrator === undefined ? false : ProjectInfo.adobeProductUse?.illustrator },
            { name: "adobeMediaEncoder", value: "Adobe Media Encoder", isChecked: ProjectInfo.adobeProductUse?.adobeMediaEncoder === undefined ? false : ProjectInfo.adobeProductUse?.adobeMediaEncoder },
            { name: "substance3D", value: "Substance 3D", isChecked: ProjectInfo.adobeProductUse?.substance3D === undefined ? false : ProjectInfo.adobeProductUse?.substance3D },
            { name: "other", value: "Other", isChecked: ProjectInfo.adobeProductUse?.other === undefined ? false : ProjectInfo.adobeProductUse?.other }
        ])
        setTextfield({
            ...textfield,
            softwareUsedToEditNote: ProjectInfo.softwareUsedToEditNote,
            ProductionOther: ProjectInfo.brieflyExplain,
            influencedCameraNote: ProjectInfo.influencedCameraNote,
        })
        setFormnine({
            cameras: ProjectInfo.cameras?.length > 0 ? ProjectInfo.cameras : [{ brand: '', model: '', desc: '', _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString() }],
            useCameraLence: ProjectInfo.useCameraLence?.length > 0 ? ProjectInfo.useCameraLence : [{ brand: '', model: '', desc: '', _id: (new Date().getTime() + Math.floor(Math.random() * 100)).toString() }]
        })
        // eslint-disable-next-line 
    }, [ProjectInfo])

    useEffect(() => {
        // window.scrollTo(0, 0)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        // eslint-disable-next-line
    }, [])

    const [textfield, setTextfield] = useState({
        softwareUsedToEditNote: ProjectInfo.softwareUsedToEditNote,
        ProductionOther: ProjectInfo.brieflyExplain,
        influencedCameraNote: ProjectInfo.influencedCameraNote,
    })

    // useEffect(() => {
    //     setalldata({ ...alldata })
    //     // eslint-disable-next-line
    //     setisSubmitted(isSubmitted)
    // }, [ProjectInfo])

    const [selectCamera] = useState(['ARRI','AATON', 'BLACKMAGIC', 'CANON', 'GOPRO', 'Kinefinity', 'PANASONIC', 'PANAVISION','PHANTOM', 'RED', 'SONY', 'OTHER',]);
    const [selectLensData] = useState(['7artisans', 'ANGENIEUX', 'ARRI/Zeiss', 'ATLAS LENS CO.', 'BLAZAR',  'CANON', 'COOKE', 'DZOFilm', 'FUJINON', 'HAWK', 'IRIX', 'KOWA', 'LEICA', 'LEITZ', 'MEIKE', 'MITAKON Zhongyi', 'NiSi', 'PANASONIC', 'PANAVISION', 'ROKINON', 'SAMYANG', 'SCHNEIDER', 'SIGMA', 'Sirui', 'SLR MAGIC',  'SONY',  'TAMRON', 'TOKINA', 'VENUS OPTICS', 'VILTROX', 'ZEISS', 'OTHER ']);





    return (
        <div id='downloadStep9'>
            <div className='step2 step_common'>
                <Header />
                <PreviewFormHeader step={pafType === "New Frontier" ? 8 : 9} />
                <div className='container form_inner'>
                    <div className='underline'>
                        <div className='step sd_justbetween'>
                            <div className='step_no'>
                                <p>Page {pafType === 'New Frontier' ? 8 : 9} of {pafType === 'New Frontier' ? 8 : 9}</p>
                            </div>
                            <div className='step_des'>
                                <p>After you submit at the end of Page {pafType === 'New Frontier' ? 8 : 9}, you cannot return to this form to make changes. For change requests, email your dedicated Artist Relations and Publicity liaisons. </p>

                                <p>Publicity and marketing materials will be provided to third-party groups, may be used on all Sundance Institute websites, blogs, and print publications before and after the Festival and will be placed in the Sundance Institute Archives. </p>
                            </div>
                        </div>
                    </div>
                    <div className="form_content">
                        <div className="form_content_top">
                            <div className="form_content_top_title">
                                <h3>Production Questions</h3>
                                <p>Sundance Film Festival receives support from partners who are interested in hearing if and how their products are being used as part of your filmmaking process. This also provides Sundance Institute with valuable information on current practices and tools. Please enter NA if not applicable.</p>
                            </div>
                        </div>
                    </div>
                    <div className="form_content_questions">
                        <div className="form_content_questions_inner">
                            <div className="question sd_flex">
                                <div className="question_label">
                                    <p>What camera did you use to shoot your project (make/model)?</p>
                                    <span className="">(Can enter up to 2)</span>
                                </div>
                                <div className="question_dropdown">
                                    {
                                        formnine.cameras.map((item, i) => {
                                            return (
                                                <div className="question_dropdown_inner sd_flex" key={item._id}>
                                                    <div className="question_dropdown_inner_wrap">
                                                        <Dropdown disable={true} options={selectCamera} name='brand'  value={item.brand} DefaultOption={'Select From Below'} id="camera_brand"/>
                                                        <div className="sd_form_group border_select">
                                                            <Dropdown disable={true} name='model' options={item.brand ? camera[item.brand.split(/\s/).join('')] : ''} value={item.model}  DefaultOption={'Select From Below'} />
                                                        </div>
                                                        {item.brand && item.model === 'ILC' &&
                                                            <div className="sd_form_group border_select">
                                                                <div className="field_row_inner sd_flex sd_aligncenter">
                                                                    <span> Which one? (i.e. R5, R3, 5D Mark IV, 1D X Mark II, Rebel T6i)</span>
                                                                    <div className="field_input relative">
                                                                        <input disabled={true} type='text' name='desc' value={item.desc} placeholder='Please enter model'/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {item.brand && item.model === 'Specialty Brand' &&
                                                            <div className="sd_form_group border_select">
                                                                <div className="field_row_inner sd_flex sd_aligncenter">
                                                                    <span style={{marginRight:'5px'}}> Which one?</span>
                                                                    <div className="field_input relative">
                                                                        <input disabled={true} type='text' name='desc' value={item.desc} placeholder='Please enter model'/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {item.model === 'Other' ?
                                                            <div className="sd_form_group border_select">
                                                                <div className="field_row_inner sd_flex sd_aligncenter">
                                                                    <span style={{marginRight:'5px'}}>Which model?</span>
                                                                    <div className="field_input relative">
                                                                        <input disabled={true} type='text' name='desc' value={item.desc} placeholder='Please enter model' />
                                                                    </div>
                                                                    {/* <span className="error" style={{ color: 'red' }} >{Validator.current.message('Pleaseentermodel', item.desc, 'Pleaseentermodel|required')}</span> */}
                                                                </div>
                                                            </div> : ''
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>



                            <DropdownQuestion disable={true} question="Did you purchase or rent this camera?" options={option.Flag1} name="cameraPurchaseOrRent" value={alldata.cameraPurchaseOrRent} fpgerror={Validator.current.message('Require', alldata.cameraPurchaseOrRent, 'Require|required')} DefaultOption={'Select From Below'} />
                            <DropdownQuestion disable={true} question="What influenced the camera selection?" options={option.Flag3} name="influencedCamera" value={alldata.influencedCamera} DefaultOption={'Select From Below'} isTextField={(alldata.influencedCamera === 'Format' || alldata.influencedCamera === 'Features') ? true : false} TextAreaName={"influencedCameraNote"} TextAreavalue={textfield.influencedCameraNote} label={alldata.influencedCamera === 'Format' ? "Which Format?" : "Which Features?"} />
                            <DropdownQuestion disable={true} question="Did you use Anamorphic or Spherical lenses?" options={option.Flag2} name="cameraAnamorphicOrSpecialLense" value={alldata.cameraAnamorphicOrSpecialLense} fpgerror={Validator.current.message('Require', alldata.cameraAnamorphicOrSpecialLense, 'Require|required')} DefaultOption={'Select From Below'} />

                            <div className="question sd_flex">
                                <div className="question_label">
                                    <p>Which lenses did you use to shoot your project?</p>
                                    <span className="">(Can enter up to 2)</span>
                                </div>
                                <div className="question_dropdown">
                                    {
                                        formnine.useCameraLence.map((item, i) => {
                                            return (
                                                <>
                                                    <div className="question_dropdown_inner sd_flex 123">
                                                        <div className="question_dropdown_inner_wrap">
                                                            <Dropdown disable={true} options={selectLensData} name='brand' value={item.brand}  DefaultOption={'Select From Below'} />
                                                            <div className="sd_form_group border_select">
                                                                <Dropdown disable={true} name='model' options={item.brand ? lens[item.brand.split(/[/\s.]/).join('')] : ''} value={item.model} DefaultOption={'Select From Below'} />
                                                            </div>
                                                            {item.model === "Other" ?
                                                                <div className="sd_form_group border_select">
                                                                    <div className="field_row_inner sd_flex sd_aligncenter">
                                                                        <span style={{marginRight:'5px'}}>Which model?</span>
                                                                        <div className="field_input relative">
                                                                            <input type='text' disabled={true} name='desc' value={item.desc} placeholder='Please enter model' />
                                                                        </div>
                                                                        <span className="error" style={{ color: 'red' }} >{Validator.current.message('Pleaseentermodel', item.desc, 'Pleaseentermodel|required')}</span>
                                                                    </div>
                                                                </div>
                                                                : ''
                                                            }
                                                        </div>
                                            
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <DropdownQuestion disable={true} question="Which software was used to edit your film?" extrafield={extrafield} options={option.SoftwareData} name="softwareUsedToEdit" TextAreaName="softwareUsedToEditNote" TextAreavalue={textfield.softwareUsedToEditNote} value={alldata.softwareUsedToEdit}  />
                            <DropdownQuestion disable={true} question="Did you use Adobe Creative Cloud software for any of the pre-production, production, post-production or marketing of your film?" options={option.Flag5} name="useAdobeProducts" value={alldata.useAdobeProducts} DefaultOption={'Select From Below'} />

                            <div className="question">
                                <div className="question_label">
                                    <p>If any Adobe solutions were used on your film, which ones?</p>
                                    <span className="">(Check all that apply.)</span>
                                </div>
                                <div className="check_col_2 sd_flex sd_flexwrap">
                                    {
                                        adobeCheck.length > 0 && adobeCheck.map((adobelist, i) => {

                                            return (
                                                <Checkbox disable={true} type="checkbox" checked={adobelist.isChecked} name={adobelist?.name} label={adobelist?.value} id={`adobe_${i}`}/>
                                            )
                                        })
                                    }
                                    {adobeOtherFlag ? <div style={{width:'350px'}}><TextField disabled={true} type="text" name="otherDesc" value={adobeListOther.otherDesc}  /></div> : ""}
                                </div>
                            </div>

                            <DropdownQuestion disable={true} question="Did you use any stock footage in your film?" options={option.Flag9} name="useStockFootage" value={alldata.useStockFootage} DefaultOption={'Select From Below'} />
                            <div className="question">
                                <div className="question_label">
                                    <p>If yes, which of the following sources did you use for your stock footage?</p>
                                    {/* <span className="sd_textuppercase" onClick={StockSelectAll}>(Check all that apply.)</span> */}
                                </div>
                                <div className="check_col_2 sd_flex sd_flexwrap">
                                    {
                                        stockFootage.length > 0 && stockFootage.map((StockFootage, i) => {
                                            return (
                                                <Checkbox disable={true} type="checkbox" checked={StockFootage.isChecked} name={StockFootage?.name} label={StockFootage?.value} id={`stock_${i}`} />
                                            )
                                        })
                                    }
                                    {stockOtherFlag ? <div style={{width:'350px'}}><TextField disabled={true} type="text" name="otherDesc" value={stockFootageOther.otherDesc} /></div> : ""}
                                </div>
                            </div>
                            <DropdownQuestion disable={true} question="What primary computer hardware did you use to complete your film?" options={option.Flag6} name="useHardware" value={alldata.useHardware} DefaultOption={'Select From Below'} />
     
                            {/* <DropdownQuestion disable={true} question="Did you use 3D real-time tools/engines in the making of this experience?" options={option.Flag8} name="realTimes3DTools" value={alldata.realTimes3DTools} DefaultOption={'Select From Below'} /> */}

                            {/* <div className="question">
                                <div className="question_label">
                                    <p >Did you use 3D real-time tools/engines in the making of this experience?</p>
                                </div>
                                <div className="check_col_2 sd_flex sd_flexwrap">
                                    {
                                        realTimes3DTools.length > 0 && realTimes3DTools.map((realTimes3DTools, i) => {
                                            return (
                                                <Checkbox disable={true} type="checkbox" checked={realTimes3DTools.isChecked} name={realTimes3DTools?.name} label={realTimes3DTools?.value} id={`realTimes3DTools_${i}`} />
                                            )
                                        })
                                    }
                                    {realTimeOtherFlag ? <div style={{width:'350px'}}><TextField disabled={true} type="text" name="otherDesc" value={realTimes3DToolsOther.otherDesc} /></div> : ""}
                                </div>
                            </div> */}
                         
                            {/* <DropdownQuestion disable={true} question="Which creative funding platform did you use to produce your film?" options={option.Flag10} name="fundingPlatform" value={alldata.fundingPlatform}  DefaultOption={'Select From Below'} />
                            <DropdownQuestion disable={true} question="Are you open to sharing distribution data on your project?" options={option.Flag9} name="sharingDistribution" value={alldata.sharingDistribution}  DefaultOption={'Select From Below'} />
                            <DropdownQuestion disable={true} question="Do you have interest in hybrid distribution?" options={option.Flag9} name="hybridDistribution" value={alldata.hybridDistribution}  DefaultOption={'Select From Below'} /> */}
                            <div className="no_border">
                                <DropdownQuestion disable={true} question=" Did you use Dropbox (or Dropbox Replay, Capture, Sign, DocSend) during the planning, production or distribution of your film/project?" name="useDropbox" value={alldata.useDropbox} options={option.Flag9}  DefaultOption={'Select From Below'} />
                                {alldata.useDropbox === "Yes" ? <TextArea disabled={true} label="Please explain how" uppercase={true}  name="ProductionOther" value={textfield.ProductionOther}  /> : ""}
                            </div>
                        </div>

                        {/* <div className="privacy_policy_link">
                            <div className="form_inner_title">
                                <h3 className="sd_flex sd_aligncenter">Privacy Policy</h3>
                            </div>
                            <div className="ass_container_990 ">
                                <p>By signing below you are agreeing to <a href="https://www.sundance.org/about/privacy-policy" target="_blank" rel="noreferrer" >Sundance Institute’s Privacy Policy.</a></p>
                            </div>
                        </div>
                        <div className="electronic_sign">
                            <div className="form_inner_title">
                                <h3 className="sd_flex sd_aligncenter">Electronic Signature</h3>
                            </div>
                            <div className="ass_container_990 ">
                                <p>
                                    On behalf of the film/project owners, I certify that the film/project team agrees that all of the above is true to the best of my knowledge and I am authorized to submit this information for this film/project. I understand that this information will appear in all Festival materials and be provided to third-party groups. I understand and consent that the Festival may makeadditional edits to the information submitted here, for reasons including but not limited to style, grammar and brevity.
                                </p>
                                <p> I agree to indemnify and hold harmless the Institute, its officers, directors, employees and other participants in any of the Institute’s programs from any claim of any type made against the Institute arising from the information I have provided here and/or the project’s participation in the Festival.</p>
                            </div>
                        </div> */}
                        {/* {isSubmitted >= 9 ? <FormFooter step={9} /> : <FormSubmitFooter step={9} onFormSave={onFormSave} />} */}
                        <PreviewFormFooter step={pafType === 'New Frontier' ? 8 : 9} rootId='downloadStep9'/> 
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}



export default Step9;